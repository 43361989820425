.required {
  color: red;
}

.main-color {
  color: #1a58a3;
}

.ant-select {
  width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100%;
  input {
    width: 100%;
  }
}

.input-sort {
  width: 50px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}

.btn-actions-prod button {
  margin-right: 20px;
}

.filter-sorted {
  background: #ececec;
  height: auto;
  padding: 12px;
  margin-bottom: 20px;

  &:hover {
    cursor: move;
  }
}

.upload-new {
  border-radius: 3px;
  border: 1px solid #ccc;
  height: 200px;
  width: 200px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
}

.custom-btn {
  align-items: center;
  display: inline-flex;
  height: 28px;
}
.details-vendor {
  position: relative;

  .full-details {
    position: absolute;
    top: 0;
    z-index: 9;
    background: #fff;
    padding: 20px;
    border-radius: 11px;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    width: 70%;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.2);

    .seperaror {
      width: 100%;
      height: 1px;
      background: #ccc;
      margin-bottom: 20px;
    }

    img {
      background: #ccc;
      width: 100%;
      height: auto;
    }
  }

  .close-details {
    background: #ccc;
    width: 25px;
    height: 25px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 8;
  }
}

.remove-region {
  width: 25px;
  height: 25px;
  background: rgb(255, 1, 1);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.required-star {
  margin-right: 4px;
}

.ant-select-selection {
  height: 38px;
}
.ant-select-selection--multiple {
  height: auto;
}

.ant-select-selection-selected-value {
  padding-top: 2px;
  color: #292929;
  font-size: 16px;
}

.btn-sm {
  font-size: 11px;
  padding: 3px 6px;
  margin-right: 4px;
}

.form-button {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.form-button .anticon-loading {
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.admin-title-super2 {
  display: flex;
}

.label-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.custom-label {
  color: #000;
  margin-bottom: 0;
  display: block;
}

/* tags */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-items: center;

  .ant-tag {
    margin-bottom: 10px;
  }

  input {
    width: 150px;
  }
}

.break-names {
  width: 115px;
  word-break: break-word;
}

/* text editor */
.rdw-emoji-modal,
.rdw-embedded-modal,
.rdw-link-modal,
.rdw-image-modal {
  right: 5px !important;
  left: auto !important;
  min-height: 240px !important;
}
.rdw-editor-wrapper {
  border: 1px solid #eaeaea;
}

.lg-button {
  width: 400px;
  display: block;
  margin: auto;
  margin-top: 30px;
}

.public-DraftStyleDefault-block {
  padding: 0 20px;
}

.attr-container {
  display: flex;
  width: 180px;
  flex-wrap: wrap;

  .attr-tag {
    background: #8dbef3;
    padding: 5px;
    color: #fff;
    border-radius: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.adminLogin {
  background-color: #f1f1f1;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    background: #fff;
    padding: 47px;
    width: 400px;
    display: block;
    margin: auto;

    .submit-btn {
      width: 100%;
      height: 45px;
      border: none;
      color: #fff;
      background: #1a58a3;
    }

    .motajer-logo {
      display: block;
      margin: auto;
    }
  }
}

.motajer-btn {
  background-color: #f94d4d !important;
  color: #fff !important;
  border-radius: 5px;
}

.admin-layout {
  padding-bottom: 30px;
  background-color: #fff2e4;
  min-height: 100vh;
  display: flex;

  &.scenario1 {
    #admin-sidebar {
      width: 85px;

      .nav-link {
        h6 {
          display: none;
        }
      }
    }

    .admin-wrapper {
      margin-left: 0;
    }
  }

  &.scenario2 {
    #admin-sidebar {
      left: 0px;
    }

    .admin-wrapper {
      margin-left: 0;
    }
  }
}

.admin-wrapper {
  margin-left: 200px;
  padding-top: 40px;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;

  .index-actions {
    .btn {
      margin-right: 5px;
    }
  }

  .no-move {
    &:hover {
      cursor: auto !important;
    }
  }

  .image-wrapper-gallary {
    position: relative;
  }
  .remove-image {
    opacity: 1;
    position: absolute;
    border: none;
    top: 0px;
    right: 35px;
    width: 20px;
    padding-bottom: 3px;
    height: 20px;
    z-index: 9999;
    color: #fff;
    background: #ff4242;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  td {
    vertical-align: middle;
    display: table-cell;
  }

  .table-img {
    width: auto;
    height: 70px;
  }
  .table-img-cover {
    width: 150px;
    height: 80px;
  }

  .image-preview {
    position: relative;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 10px;
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;

    &.single {
      width: auto;
      height: 240px;
    }

    &.single-blog {
      display: block;
      margin: auto;
      height: 240px;
      width: 500px;
    }

    &.product-one {
      width: auto;
    }

    &:hover {
      cursor: move;
      .remove-image {
        opacity: 1;
      }
    }

    .name-preview {
      background-color: #ffffff63;
      text-align: center;
      position: absolute;
      bottom: 27px;
      display: block;
      right: 0;
      color: #000;
      left: 0;
      padding-top: 8px;
      margin: auto;
      align-items: center;
      justify-content: center;
      height: 36px;
      border-radius: 30px;
      width: 75%;
    }

    img {
      height: 100%;
      display: block;
      margin: auto;
    }
  }

  .admin-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .admin-title-super {
      display: flex;
      width: 50%;

      .admin-title-search {
        width: 70%;
        margin-right: 20px;
      }
    }
  }

  .bg-color1 {
    background: #673ab7 !important;
  }

  .bg-color2 {
    background: #ffc107 !important;
  }

  .bg-color3 {
    background: #795548 !important;
  }

  .bg-color4 {
    background: #9e9e9e !important;
  }

  .bg-color5 {
    background: #0d47a1 !important;
  }

  .bg-cyan {
    background: #27a9e3 !important;
  }

  .bg-green {
    background: #28b779 !important;
  }

  .bg-orange {
    background: #da542e !important;
  }

  .bg-blue {
    background: #2255a4 !important;
  }

  .statics-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h3,
    h1,
    h2,
    h4,
    h5,
    h6,
    svg,
    p {
      color: #fff !important;
    }

    svg {
      display: block;
      margin: 10px auto;
    }
  }

  .admin-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: 0px solid transparent;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    box-shadow: 3px 2px 6px -1px rgba(0, 0, 0, 0.1);

    &.pd {
      padding: 30px;
    }
  }
}

@media (max-width: 991px) {
  .admin-layout {
    .admin-sidebar {
      left: -200px;
      width: 0px;
    }

    .admin-wrapper {
      margin-left: 0;
    }
  }
}

@media (max-width: 900px) {
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.sub-category-td {
  td {
    padding-left: 50px !important;
    background: #fbfbfb;
  }
}

.image-preview-image {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  img {
    width: 100%;
  }
}
