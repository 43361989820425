.admin-sidebar {
  position: fixed;
  overflow-y: scroll;
  width: 200px;
  left: 0;
  height: 100%;
  top: 0px;
  z-index: 10;
  padding-bottom: 30px;
  background-color: #1c2835;
  transition: all 0.35s ease-in-out;

  &.scenario3 {
    left: 0px !important;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  .brand-logo {
    color: #fff;
    display: block;
    margin: 0px auto 20px;
  }

  .pages-nav {
    background: #0b2a50;
  }

  .drop-down-pages {
    margin-right: 10px;
    font-size: 20px;
  }

  .storage {
    background-color: #f6bfa0;
    padding: 10px;
    text-align: center;
    display: block;
  }

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .admin-logo-cont {
    background-color: #fff;
    padding: 10px;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 4px;
  }

  .sub-menu {
    background: #141619;
    display: none;
    a {
      padding-left: 50px;
    }
  }

  .nav-item,
  .nav-link {
    &:hover {
      cursor: pointer;
      background-color: #0e3462;
    }
  }

  .nav-link {
    color: #fff;
    padding: 14px 15px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    line-height: 25px;
    user-select: none;
    opacity: 1;
    &:hover {
      cursor: pointer;
    }

    h6 {
      color: #fff;
      margin-bottom: 0;
    }

    .icon-class-name {
      margin-right: 7px;
      font-size: 20px;
      color: coral;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      background: #27a9e3;
      color: #fff;
      opacity: 1;
    }
  }
}
